import {Component, OnInit} from '@angular/core';
import {CognitoService} from '../../services/cognito.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastController} from '@ionic/angular';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss', '../pages.scss'],
})
export class LoginPage implements OnInit {
    private returnUrl: string;

    loading = false;

    constructor(
        private route: ActivatedRoute,
        private cognito: CognitoService,
        private router: Router,
        private toastController: ToastController) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

        // First see, if we are here wrongly
        if (this.cognito.isLoggedIn()) {
            console.log('User is logged in -> returning', this.returnUrl);
            this.router.navigateByUrl(this.returnUrl);
            return;
        }

        const cb = (() => {
            this.cognito.removeAuthCallback(cb);
            console.log('Returning to', this.returnUrl);
            this.router.navigateByUrl(this.returnUrl);
        });
        this.cognito.addAuthCallback(cb);
        // If nothing works then we need to be here
    }

    login(form) {
        this.loading = true;

        this.cognito.authenticate(form.value.email, form.value.password)
            .then(() => {
                console.log('Returning to', this.returnUrl);
                this.router.navigateByUrl(this.returnUrl);
            })
            .catch(async err => {
                console.log(err);
                let toast;
                switch (err.code) {
                    case 'UserNotFoundException':
                    case 'NotAuthorizedException':
                        toast = await this.toastController.create({
                            message: 'E-Mail or Password is incorrect.',
                            duration: 2000,
                            color: 'danger'
                        });
                        break;
                    default:
                        toast = await this.toastController.create({
                            message: 'An unknown error occurred.',
                            duration: 2000,
                            color: 'danger'
                        });
                        break;
                }
                toast.present();
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /*
    async resetPassword() {
        if (this.confirmPassword !== this.newPassword) {
            const toast = await this.toastController.create({
                message: 'Passwords do not match',
                duration: 2000,
                color: 'danger'
            });
            toast.present();
            return;
        }

        this.cognito.confirmPassword(this.mail, this.resetCode, this.confirmPassword)
            .then()
            .catch(async err => {
                console.log(err);
                const toast = await this.toastController.create({
                    message: err.message,
                    duration: 2000,
                    color: 'danger'
                });
                toast.present();
            });
    }
     */
}
