import {Component} from '@angular/core';
import {MqttService, MqttSubscription} from '../../services/mqtt.service';
import {CognitoService} from '../../services/cognito.service';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-dev',
    templateUrl: './dev-page.component.html',
    styleUrls: ['./dev-page.component.scss'],
})
export class DevPage {
    private sub: MqttSubscription;

    constructor(private cognito: CognitoService,
                private mqtt: MqttService,
                private api: ApiService,
                private router: Router) {
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    getToggleControllerLastChange() {
        this.api.getToggleControllerLastChange('Hs100_Toggle_1')
            .subscribe(data => {
                console.log(data);
            });
    }

    getToggleControllerById() {
        this.api.getToggleControllerById('Hs100_Toggle_1')
            .subscribe(data => {
                console.log(data);
            });
    }

    getSensorById() {
        this.api.getSensorById('DS18B20_Sensor_1')
            .subscribe(data => {
                console.log(data);
            });
    }

    getToggleControllers() {
        this.api.getToggleControllers()
            .subscribe(data => {
                console.log(data);
            });
    }

    getSensors() {
        this.api.getSensors()
            .subscribe(data => {
                console.log(data);
            });
    }

    getToggleControllerHistory() {
        this.api.getToggleControllerHistory('Hs100_Toggle_1', new Date(2020, 1))
            .subscribe(data => {
                console.log(data);
            });
    }

    getSensorHistory() {
        this.api.getSensorHistory('DS18B20_Sensor_1', new Date(2020, 1))
            .subscribe(data => {
                console.log(data);
            });
    }

    getDevices() {
        this.api.getDevices()
            .subscribe(data => {
                console.log(data);
            });
    }

    getToken() {
        console.log(this.cognito.getToken().token);
    }

    subscribe() {
        this.mqtt.subscribe('ping/+/pong', (topic, payload) => {
            console.log(topic, payload);
        })
            .then(subscription => {
                this.sub = subscription;
                console.log('Subsctiption:', subscription);
            })
            .catch(err => {
                console.log('Error subscribing:', err);
            });
    }

    publish() {
        this.mqtt.publish('test', '{"test": "top"}')
            .then(success => {
                console.log('Publish success:', success);
            })
            .catch(err => {
                console.log('Error publishing:', err);
            });
    }

    unsubscribe() {
        this.mqtt.unsubscribe(this.sub)
            .then(success => {
                console.log('Unsubscribe:', success);
            })
            .catch(err => {
                console.log('Error unsubscribing:', err);
            });
    }
}
