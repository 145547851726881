import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import * as AWSIoTData from 'aws-iot-device-sdk';
import {uuid4} from '@capacitor/core/dist/esm/util';
import {ISubscriptionGrant} from 'mqtt';
import {CognitoService} from './cognito.service';

@Injectable({
    providedIn: 'root'
})
export class MqttService {
    private readonly endpoint = 'a263grj2ivekwm-ats.iot.eu-central-1.amazonaws.com';

    private device: AWSIoTData.device;
    private subscriptions: Array<MqttSubscription> = new Array<MqttSubscription>();

    constructor(private cognito: CognitoService) {
    }

    public logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.device) {
                resolve();
                return;
            }

            this.device.end(true, () => {
                console.log('MQTT Session ended');
                resolve();
            });
        });
    }

    public reload(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.subscriptions = new Array<MqttSubscription>();

            const credentials = AWS.config.credentials;

            if (!credentials) {
                return reject(false);
            }

            this.device = new AWSIoTData.device({
                region: AWS.config.region,
                host: this.endpoint,
                clientId: this.cognito.getUser().getUsername() + '-' + uuid4(),
                protocol: 'wss',
                port: 443,
                maximumReconnectTimeMs: 8000,
                debug: false,
                accessKeyId: credentials.accessKeyId,
                secretKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            });

            this.device.on('connect', () => {
                resolve(true);
            });
            this.device.on('close', () => {
                reject('Connection closed');
            });
            this.device.on('offline', () => {
                reject('Offline');
            });
            this.device.on('error', error => {
                reject(error);
            });

            this.device.on('message', (topic, payload) => {
                this.subscriptions.forEach(sub => {
                    if (sub.matches(topic)) {
                        sub.onMessage(topic, payload.toString());
                    }
                });
            });
        });
    }

    public publish(topic: string, payload: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            // Force add the username
            const tp = this.cognito.getUser().getUsername() + '/' + topic;

            this.device.publish(tp, payload, null, err => {
                if (err) {
                    return reject(err);
                }

                return resolve(true);
            });
        });
    }

    public subscribe(topic: string, onMessage: (topic: string, payload: string) => void): Promise<MqttSubscription> {
        return new Promise<MqttSubscription>((resolve, reject) => {
            // Force add the username
            this.device.subscribe(this.cognito.getUser().getUsername() + '/' + topic, null, (err: Error, granted: ISubscriptionGrant[]) => {
                if (err) {
                    return reject(err);
                }

                if (!granted) {
                    return reject('Invalid topic');
                }

                const sub = new MqttSubscription(granted[0].topic, onMessage);
                this.subscriptions.push(sub);
                return resolve(sub);
            });
        });
    }

    public unsubscribe(subscription: MqttSubscription): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const index = this.subscriptions.indexOf(subscription, 0);
            if (index < 0) {
                return reject('Subscription not found');
            }

            let allowUnsub = true;
            this.subscriptions.forEach(sub => {
                if (sub.topic === subscription.topic && sub !== subscription) {
                    allowUnsub = false;
                }
            });

            if (allowUnsub) {
                this.device.unsubscribe(subscription.topic);    // Callback not fired.
                this.subscriptions.splice(index, 1);
                return resolve(true);
            }

            return resolve(true);
        });
    }
}

export class MqttSubscription {
    private readonly topicPattern: string;
    private readonly id = uuid4();

    constructor(public topic: string, public onMessage: (topic: string, payload: string) => void) {
        this.topicPattern = '^' + topic.replace('+', '[^/]+').replace('#', '.+') + '$';
    }

    public matches(topic: string): boolean {
        return topic.match(this.topicPattern) != null;
    }
}
