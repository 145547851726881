<ion-header>
    <ion-toolbar>
        <ion-title>{{ 'signupPage.title' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form #userSignup="ngForm" (ngSubmit)="register(userSignup)" *ngIf="!this.loading" class="full-height">
        <ion-grid class="full-height">
            <ion-row class="full-height flex-center">
                <ion-col size-md="6" size-lg="5" size-xs="12">
                    <ion-item class="rounded-input">
                        <ion-input name="email" type="text" placeholder="{{ 'global.email' | translate }}" ngModel
                                   required></ion-input>
                    </ion-item>

                    <ion-item class="rounded-input ion-margin-top">
                        <ion-input name="password" type="password" placeholder="{{ 'global.password' | translate }}"
                                   minlength="6" ngModel
                                   required></ion-input>
                    </ion-item>

                    <ion-item class="rounded-input ion-margin-top">
                        <ion-input name="confirm" type="password" placeholder="{{ 'signupPage.confirm' | translate }}"
                                   minlength="6" ngModel
                                   required></ion-input>
                    </ion-item>

                    <ion-row class="ion-padding-top">
                        <ion-col class="ion-no-padding">
                            <ion-button type="submit" shape="round" [disabled]="userSignup.invalid"
                                        expand="block">{{ 'signupPage.button' | translate }}
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
    <div class="full-width full-height flex-center" *ngIf="this.loading">
        <ion-spinner color="primary" class="full-spinner"></ion-spinner>
    </div>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-row class="ion-justify-content-center bottom-notice">
        <ion-label>
            {{ 'signupPage.existing' | translate }} <a
                [routerLink]="['/login']">{{ 'signupPage.signIn' | translate }}</a>
        </ion-label>
    </ion-row>
</ion-footer>

