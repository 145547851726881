import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CognitoService} from '../../services/cognito.service';
import {ToastController} from '@ionic/angular';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.page.html',
    styleUrls: ['./signup.page.scss', '../pages.scss'],
})
export class SignupPage implements OnInit {
    loading = false;

    constructor(
        private cognito: CognitoService,
        private router: Router,
        private toastController: ToastController) {
    }

    ngOnInit() {
    }

    async register(form) {
        if (form.value.confirm !== form.value.password) {
            const toast = await this.toastController.create({
                message: 'Passwords do not match.',
                duration: 2000,
                color: 'danger'
            });
            toast.present();
            return;
        }

        this.loading = true;

        this.cognito.register(form.value.email, form.value.password).then(() => {
            this.router.navigateByUrl('/');
        }).catch(async err => {
            console.error(err);
            const toast = await this.toastController.create({
                message: err.message || 'An unknown error occurred.',
                duration: 2000,
                color: 'danger'
            });
            toast.present();
        })
            .finally(() => {
                this.loading = false;
            });
    }
}
