import {AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import AWS from 'aws-sdk';
import {interval} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CognitoService {
    private readonly IdentityPoolId = 'eu-central-1:e8c52c22-b675-4aa7-92db-161c0c038795';
    private readonly Region = 'eu-central-1';
    private readonly PoolData = {
        UserPoolId: 'eu-central-1_hWK4xuG23',
        ClientId: '4te3osk3ij1n7k8dbm6ilhhraj'
    };
    private readonly userPool = new CognitoUserPool(this.PoolData);

    private session: CognitoUserSession;
    private authCallbacks: Array<((value: CognitoUserSession) => void)> = new Array<((value: CognitoUserSession) => void)>();

    constructor(private router: Router) {
        const s = interval(900000);
        s.subscribe(() => {
            this.renew()
                .then(session => this.session = session)
                .catch(() => this.router.navigate(['login']));
        });
    }

    public isLoggedIn(): boolean {
        if (!this.session) {
            console.log('No session found -> User is not logged in');
            return false;
        }

        console.log('Session found, validity:', this.session.isValid());
        return this.session.isValid();
    }

    public removeAuthCallback(callback: ((value: CognitoUserSession) => void)) {
        const index = this.authCallbacks.indexOf(callback, 0);
        this.authCallbacks.splice(index, 1);
    }

    public addAuthCallback(callback: ((value: CognitoUserSession) => void)) {
        this.authCallbacks.push(callback);
    }

    public getToken(): SessionResponse {
        if (!this.session) {
            return new SessionResponse('', false);
        }

        return new SessionResponse(this.session.getIdToken().getJwtToken(), true);
    }

    public getUser(): CognitoUser {
        return this.userPool.getCurrentUser();
    }

    public async authenticate(email: string, password: string): Promise<CognitoUserSession> {
        return new Promise<CognitoUserSession>((resolve, reject) => {
            const authenticationData = {
                Username: email,
                Password: password,
            };

            const userData = {
                Username: email,
                Pool: this.userPool
            };

            const cognitoUser = new CognitoUser(userData);
            const authenticationDetails = new AuthenticationDetails(
                authenticationData
            );

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session: CognitoUserSession) => {
                    this.setAwsConfig(session);

                    // @ts-ignore
                    AWS.config.credentials.refresh((err) => {
                        if (err) {
                            return reject(err);
                        } else {
                            console.log(this.authCallbacks);
                            this.authCallbacks.forEach(cb => cb(session));
                            return resolve(session);
                        }
                    });
                },
                onFailure: (err) => {
                    reject(err);
                },

            });
        });
    }

    public async register(email: string, password: string): Promise<CognitoUser> {
        return new Promise<CognitoUser>((resolve, reject) => {
            const attributes = [new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            })];

            this.userPool.signUp(email, password, attributes, null, (err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data.user);
            });
        });
    }

    public async confirmRegistration(email: string, code: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const userData = {
                Username: email,
                Pool: this.userPool
            };

            const cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmRegistration(code, true, (err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data);
            });
        });
    }

    public async resendConfirmationCode(email: string): Promise<string> {
        return new Promise<any>((resolve, reject) => {
            const userData = {
                Username: email,
                Pool: this.userPool
            };

            const cognitoUser = new CognitoUser(userData);
            cognitoUser.resendConfirmationCode((err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data);
            });
        });
    }

    public async confirmPassword(email: string, confirmationCode: string, newPassword: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const userData = {
                Username: email,
                Pool: this.userPool
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.confirmPassword(confirmationCode, newPassword, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess() {
                    resolve();
                },
            });
        });
    }

    public async renew(): Promise<CognitoUserSession> {
        console.log('Renewing session');

        return new Promise<CognitoUserSession>((resolve, reject) => {
            if (!this.userPool.getCurrentUser()) {
                console.log('No user logged in');
                return reject('No user');
            }

            this.userPool.getCurrentUser().getSession((err, session) => {
                if (err) {
                    console.log('Error getting session for user', err);
                    return reject(err);
                }

                this.userPool.getCurrentUser().refreshSession(session.getRefreshToken(), (err1, newSession: CognitoUserSession) => {
                    if (err1) {
                        console.log('Error refreshing session for user', err1);
                        return reject(err1);
                    }

                    this.setAwsConfig(newSession);

                    // @ts-ignore
                    AWS.config.credentials.refresh(err2 => {
                        if (err2) {
                            console.log('Error refreshing credentials for user', err2);
                            return reject(err2);
                        } else {
                            console.log('New credentials acquired');
                            this.authCallbacks.forEach(cb => cb(newSession));
                            return resolve(newSession);
                        }
                    });
                });
            });
        });
    }

    public logout() {
        this.getUser().signOut();
        this.session = null;
    }

    private setAwsConfig(session: CognitoUserSession) {
        console.log('Storing new session');
        this.session = session;

        AWS.config.region = this.Region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: this.IdentityPoolId,
            Logins: {
                'cognito-idp.eu-central-1.amazonaws.com/eu-central-1_hWK4xuG23': session.getIdToken().getJwtToken()
            }
        });
    }
}

export class SessionResponse {
    token: string;
    success: boolean;

    constructor(token: string, success: boolean) {
        this.token = token;
        this.success = success;
    }
}
