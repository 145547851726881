<ion-app>
    <ion-split-pane when="lg" contentId="main">
        <!--  the side menu  -->
        <ion-menu contentId="main">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{ 'sideMenu.title' | translate }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content>
                <ion-list>
                    <ion-item-divider>{{ 'sideMenu.oneControl' | translate }}</ion-item-divider>
                    <ion-item disabled="true" [routerLink]="'/tabs/sensor'">{{ 'sensorTab.tabName' | translate }}</ion-item>
                    <ion-item disabled="true" [routerLink]="'/tabs/controller'">{{ 'controlsTab.tabName' | translate }}</ion-item>

                    <ion-item-divider>{{ 'sideMenu.myAccount' | translate }}</ion-item-divider>
                    <ion-item disabled="true">{{ 'sideMenu.myAccount' | translate }}</ion-item>
                    <ion-item disabled="true">{{ 'sideMenu.myDevices' | translate }}</ion-item>
                    <ion-item [routerLink]="'/logout'">{{ 'sideMenu.logout' | translate }}</ion-item>

                    <ion-item-divider>{{ 'sideMenu.about' | translate }}</ion-item-divider>
                    <ion-item disabled="true">{{ 'sideMenu.aboutOne' | translate }}</ion-item>
                </ion-list>
            </ion-content>
        </ion-menu>

        <!-- the main content -->
        <ion-router-outlet id="main">
            <div style="width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                <ion-spinner style="width:12%;height:12%" name="dots" color="secondary"></ion-spinner>
                <ion-label>Loading...</ion-label>
            </div>
        </ion-router-outlet>
    </ion-split-pane>

</ion-app>
