import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginPage} from './pages/login/login.page';
import {LoginPageModule} from './pages/login/login.module';
import {DevPage} from './pages/dev/dev-page.component';
import {DevPageModule} from './pages/dev/dev-page.module';
import {AuthGuard} from './services/auth-guard.service';
import {SignupPage} from './pages/signup/signup.page';
import {SignupPageModule} from './pages/signup/signup.module';
import {LogoutComponent} from './pages/logout/logout.component';
import {LogoutComponentModule} from './pages/logout/logout.module';


const routes: Routes = [
    {path: 'login', component: LoginPage},
    {path: 'logout', component: LogoutComponent},
    {path: 'signup', component: SignupPage},
    {
        path: 'dev',
        component: DevPage,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
        LoginPageModule,
        DevPageModule,
        SignupPageModule,
        LogoutComponentModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
