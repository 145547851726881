<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title>Development</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">Development</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-button (click)="subscribe()">Subscribe</ion-button>
    <ion-button (click)="publish()">Publish</ion-button>
    <ion-button (click)="unsubscribe()">Unsubscribe</ion-button>
    <ion-button (click)="getSensorHistory()">Sensor History</ion-button>
    <ion-button (click)="getDevices()">Get Devices</ion-button>
    <ion-button (click)="getSensors()">Get Sensors</ion-button>
    <ion-button (click)="getToggleControllers()">Get Toggles</ion-button>
    <ion-button (click)="getSensorById()">Get Sensor</ion-button>
    <ion-button (click)="getToggleControllerById()">Get Toggle</ion-button>
    <ion-button (click)="getToggleControllerHistory()">Toggle History</ion-button>
    <ion-button (click)="getToggleControllerLastChange()">Toggle Newest</ion-button>
    <ion-button (click)="getToken()">Get Token</ion-button>
    <ion-button (click)="goToHome()">Home</ion-button>
</ion-content>
