import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SensorHistory, SensorReading} from '../models/sensor-reading';
import {Observable} from 'rxjs';
import {Sensor} from '../models/sensor';
import {ToggleController} from '../models/toggle-controller';
import {ToggleStateChange} from '../models/toggle-state-change';
import {Device} from '../models/device';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private readonly apiURL = 'https://vusbnq8rig.execute-api.eu-central-1.amazonaws.com/Dev';

    constructor(private httpClient: HttpClient) {
    }

    public getDevices(): Observable<Device> {
        return this.get<Device>(`${this.apiURL}/devices`);
    }

    public getToggleControllers(): Observable<ToggleController[]> {
        return this.get<ToggleController[]>(`${this.apiURL}/devices/toggle`);
    }

    public getToggleControllerLastChange(controllerId: string): Observable<ToggleStateChange> {
        return this.get<ToggleStateChange>(`${this.apiURL}/devices/toggle/${controllerId}/state_changes/newest`)
            .pipe(
                map(response => {
                    response.timeStamp = new Date(response.timeStamp);
                    return response;
                })
            );
    }

    public getToggleControllerHistory(controllerId: string, from: Date, to?: Date, expand?: boolean): Observable<ToggleStateChange[]> {
        const toParam = (to || new Date()).getTime();
        const expandParam = expand || false;

        return this.get<ToggleStateChange[]>(
            `${this.apiURL}/devices/toggle/${controllerId}/state_changes?from=${from.getTime()}&to=${toParam}&expand=${expandParam}`)
            .pipe(
                map(response => {
                    response.forEach(elem => {
                        elem.timeStamp = new Date(elem.timeStamp);
                    });
                    return response;
                })
            );
    }

    public getSensors(): Observable<Sensor[]> {
        return this.get<Sensor[]>(`${this.apiURL}/devices/sensor`);
    }

    public getSensorLastValue(sensorId: string): Observable<SensorReading> {
        return this.get<SensorReading>(`${this.apiURL}/devices/sensor/${sensorId}/history/newest`)
            .pipe(
                map(response => {
                    response.timeStamp = new Date(response.timeStamp);
                    return response;
                })
            );
    }

    public getSensorHistory(sensorId: string, from: Date, to?: Date, maxItems?: number): Observable<SensorHistory> {
        const toParam = (to || new Date()).getTime();
        const maxItemsParam = maxItems || 150;

        return this.get<SensorHistory>(
            `${this.apiURL}/devices/sensor/${sensorId}/history?from=${from.getTime()}&to=${toParam}&maxItems=${maxItemsParam}`)
            .pipe(
                map(response => {
                    response.max.timeStamp = new Date(response.max.timeStamp);
                    response.min.timeStamp = new Date(response.min.timeStamp);
                    response.current.timeStamp = new Date(response.current.timeStamp);
                    response.items.forEach(elem => {
                        elem.timeStamp = new Date(elem.timeStamp);
                    });
                    return response;
                })
            );
    }

    public getSensorById(sensorId: string): Observable<Sensor> {
        return this.get<Sensor>(`${this.apiURL}/devices/sensor/${sensorId}`);
    }

    public getToggleControllerById(controllerId: string): Observable<ToggleController> {
        return this.get<ToggleController>(`${this.apiURL}/devices/toggle/${controllerId}`);
    }

    private get<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url);
    }
}
