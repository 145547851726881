import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {TranslateService} from '@ngx-translate/core';
import {MqttService} from './services/mqtt.service';
import {CognitoService} from './services/cognito.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private mqtt: MqttService,
        private cognito: CognitoService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.translate.setDefaultLang('en');
        this.translate.use(this.translate.getBrowserLang());

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });

        // Register MQTT as a callback in Cognito to create a connection after the login succeeds
        const cb = (() => {
            this.mqtt.reload()
                .then(() => {
                    // Only execute the connect once
                    this.cognito.removeAuthCallback(cb);
                })
                .catch(err => {
                    console.log('Error connecting:', err);
                });
        });

        this.cognito.addAuthCallback(cb);

        // Try to renew the Cognito session
        this.cognito.renew()
            .then()
            .catch(() => console.log('User is not logged in'));
    }
}
