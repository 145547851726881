import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CognitoService} from './cognito.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private cognito: CognitoService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>((resolve, reject) => {
            if (this.cognito.isLoggedIn()) {
                // logged in so return true
                console.log('User is logged in');
                return resolve(true);
            }

            this.cognito.renew()
                .then(() => resolve(true))
                .catch(() => {
                    console.log('User is not logged in');
                    this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
                    return reject(false);
                });
        });
    }
}
