import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CognitoService} from '../../services/cognito.service';
import {MqttService} from '../../services/mqtt.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: [],
})
export class LogoutComponent implements OnInit {

    constructor(
        private router: Router,
        private cognito: CognitoService,
        private mqtt: MqttService
    ) {
    }

    ngOnInit() {
        this.cognito.logout();
        this.mqtt.logout();
        this.router.navigateByUrl('/login').then(() => window.location.reload());
    }
}
